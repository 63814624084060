import ExcelJS from "exceljs";
import { MonthNumber } from "../../constants/MonthNumber";
import { ICategory, IProject, ITask, ITimesheet } from "../../models";

interface ITimesheetProps {
  worksheet: ExcelJS.Worksheet;
  chosenTimesheet: ITimesheet;
  tasks: ITask[];
  translate: any;
  logo: number;
  chosenProject?: any;
  month?: string
}

export const Timesheet = ({
  worksheet,
  chosenTimesheet,
  translate,
  logo,
  chosenProject,
  month,
  ...props
}: ITimesheetProps) => {
  let tasks = props.tasks.map((item) => ({
    ...item,
    hours: +(item?.hours).toFixed(2),
  }));

  const chosenProjectId = chosenProject?.id || chosenProject?.value;
  const chosenProjectName = chosenProject?.name || chosenProject?.label;

  const documentAuthor = `${chosenTimesheet?.user?.first_name} ${chosenTimesheet?.user?.last_name}`;
  const approvalAuthor = `${chosenTimesheet?.manager?.first_name} ${chosenTimesheet?.manager?.last_name}`;
  const approvalDate =
    chosenTimesheet?.status === "approve" ? chosenTimesheet?.updated_at : "";

  if (chosenProjectId) {
    worksheet.columns = [
      { header: "", key: "col0", width: 3.33 },
      { header: "", key: "col1", width: 13.5 },
      // {header: '', key: 'col2', width: 13.67},
      { header: "", key: "col3", width: 12.0 },
      { header: "", key: "col4", width: 12.0 },
      { header: "", key: "col5", width: 13.33 },
      { header: "", key: "col6", width: 43.0 },
      { header: "", key: "colDel", width: 15.0 },
    ];
  } else {
    worksheet.columns = [
      { header: "", key: "col0", width: 3.33 },
      { header: "", key: "col1", width: 13.5 },
      { header: "", key: "col2", width: 13.67 },
      { header: "", key: "col3", width: 12.0 },
      { header: "", key: "col4", width: 12.0 },
      { header: "", key: "col5", width: 13.33 },
      { header: "", key: "col6", width: 43.0 },
      { header: "", key: "colDel", width: 15.0 },
    ];
  }

  worksheet.addRow({});
  worksheet.getRow(1).height = 25;

  const styleForHeader: Partial<ExcelJS.Style> = {
    font: { bold: true, size: 20 },
    alignment: { vertical: "middle", horizontal: "left" },
  };

  worksheet.mergeCells("B2:E2");
  worksheet.getCell("B2").value = "Activity Timesheet";
  worksheet.getCell("B2").style = styleForHeader;

  worksheet.addRow({});

  worksheet.mergeCells("B4:E4");
  worksheet.getCell("B4").value = {
    richText: [
      { text: "Expert Name: ", font: { bold: true, size: 12 } },
      { text: String(documentAuthor), font: { size: 12, italic: true } },
    ],
  };

  if (chosenProjectId) {
    worksheet.mergeCells("B5:E5");
    worksheet.getCell("B5").value = {
      richText: [
        { text: "Project number: ", font: { bold: true, size: 12 } },
        { text: chosenProjectName, font: { size: 12, italic: true } },
      ],
    };
  }

  const date = `${MonthNumber()[Number(month)]?.en_title}, 20${chosenTimesheet?.date && chosenTimesheet?.date[6]}${chosenTimesheet?.date && chosenTimesheet?.date[7]}`
  worksheet.mergeCells(
    `B${chosenProjectId ? 6 : 5}:E${chosenProjectId ? 6 : 5}`
  );
  worksheet.getCell(`B${chosenProjectId ? 6 : 5}}`).value = {
    richText: [
      { text: "Period: ", font: { bold: true, size: 12 } },
      { text: date, font: { size: 12, italic: true } },
    ],
  };

  if (!chosenProjectId) worksheet.addRow({});
  worksheet.addRow({});

  const styleForTableHeader: Partial<ExcelJS.Style> = {
    font: { bold: true, size: 11, color: { argb: "000000" } },
    alignment: { vertical: "middle", horizontal: "center" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "F8C006" },
    },
    border: {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    },
  };

  const timesheetTableHeader: any = {
    col1: "Date",
    col2: "Project Num",
    col3: "Home/Field",
    col4: "Working days",
    col5: "Task",
    col6: "Activity description",
  };

  if (chosenProjectId) delete timesheetTableHeader.col2;

  const rowNumberBeforeHeader = worksheet.rowCount;

  worksheet.addRow(timesheetTableHeader).eachCell((cell, colNumber) => {
    cell.style = styleForTableHeader;
  });

  const styleForTableBody: Partial<ExcelJS.Style> = {
    font: { size: 11 },
    border: {
      top: { style: "hair", color: { argb: "000000" } },
      left: { style: "hair", color: { argb: "000000" } },
      bottom: { style: "hair", color: { argb: "000000" } },
      right: { style: "hair", color: { argb: "000000" } },
    },
    alignment: { vertical: "middle", horizontal: "center", wrapText: true },
  };

  const rowNumberBeforeTable = worksheet.rowCount;

  const tasksReversed = tasks.slice().reverse();
  tasks
    .slice()
    .reverse()
    .map((item: ITask, index: number) => {
      const isLastRow = index === tasksReversed.length - 1;

      worksheet
        .addRow({
          col1: item.date.replaceAll("/", "."),
          col2: item.project.name,
          col3: item.work_place === "home" ? "Home" : "Field",
          col4: item.hours / 8,
          col5: item.category.name,
          col6: item.task,
        })
        .eachCell((cell, colNumber) => {
          cell.style = styleForTableBody;

          if (colNumber === 2) {
            cell.style = {
              ...styleForTableBody,
              border: {
                ...styleForTableBody.border,
                left: { style: "thin", color: { argb: "000000" } },
              },
            };
            cell.numFmt = "dd/mm/yy";
          } else if (colNumber === 5) {
            cell.style = { ...styleForTableBody };
            cell.numFmt = "0.00";
          } else if (
            (colNumber === 7 && !chosenProjectId) ||
            (colNumber === 6 && chosenProjectId)
          ) {
            cell.style = {
              ...styleForTableBody,
              alignment: {
                ...styleForTableBody.alignment,
                horizontal: "left",
              },
              border: {
                ...styleForTableBody.border,
                right: { style: "thin", color: { argb: "000000" } },
              },
            };
          }

          if (isLastRow) {
            cell.style = {
              ...styleForTableBody,
              border: {
                ...styleForTableBody.border,
                bottom: { style: "thin", color: { argb: "000000" } },
              },
            };

            if (colNumber === 2) {
              cell.style = {
                ...styleForTableBody,
                border: {
                  ...styleForTableBody.border,
                  left: { style: "thin", color: { argb: "000000" } },
                  bottom: { style: "thin", color: { argb: "000000" } },
                },
              };
              cell.numFmt = "dd/mm/yy";
            } else if (
              (colNumber === 7 && !chosenProjectId) ||
              (colNumber === 6 && chosenProjectId)
            ) {
              cell.style = {
                ...styleForTableBody,
                alignment: {
                  ...styleForTableBody.alignment,
                  horizontal: "left",
                },
                border: {
                  ...styleForTableBody.border,
                  right: { style: "thin", color: { argb: "000000" } },
                  bottom: { style: "thin", color: { argb: "000000" } },
                },
              };
            }
          }
        });

      // if(chosenProjectId) {
      //     worksheet.mergeCells(`F${index + 1 + rowNumberBeforeTable}:G${index + 1 + rowNumberBeforeTable}`)
      // } else {
      //     worksheet.mergeCells(`G${index + 1 + rowNumberBeforeTable}:H${index + 1 + rowNumberBeforeTable}`)
      // }
    });

  // if(chosenProjectId) {
  //     worksheet.mergeCells(`F${rowNumberBeforeHeader + 1}:G${rowNumberBeforeHeader + 1}`)
  // } else {
  //     worksheet.mergeCells(`G${rowNumberBeforeHeader + 1}:H${rowNumberBeforeHeader + 1}`)
  // }

  worksheet.addRow({});
  worksheet
    .addRow({
      col1: "Summary",
    })
    .eachCell((cell, colNumber) => {
      cell.style = { font: { size: 18, bold: true } };
    });
  worksheet.addRow({});

  let bottomTablesHeader: any = {
    col1: "Project",
    col2: "Working Days",
    col4: "Home/Field",
    col5: "Working Days",
  };

  if (chosenProjectId) {
    bottomTablesHeader = {
      col1: "Home/Field",
      col3: "Working Days",
    };
  }

  worksheet.addRow(bottomTablesHeader).eachCell((cell, colNumber) => {
    cell.style = { ...styleForTableHeader };
  });

  const rowNumberAfterHeader = worksheet.rowCount;

  const allProjects = tasks.reduce((arr: IProject[], cur) => {
    if (!arr.some((item) => item.id === cur.project.id)) {
      arr.push(cur.project);
    }

    return arr;
  }, []);
  allProjects.map((project, index) => {
    const sum = +tasks
      .filter((item) => item.project.id === project.id)
      .reduce((curr: number, arr) => {
        return (curr += arr.hours);
      }, 0);

    worksheet
      .addRow({
        col1: project.name,
        col2: sum / 8,
      })
      .eachCell((cell, colNumber) => {
        cell.style = {
          ...styleForTableBody,
          font: { size: 10 },
          border: {
            top: { style: "hair", color: { argb: "000000" } },
            left: { style: "hair", color: { argb: "000000" } },
            bottom: { style: "hair", color: { argb: "000000" } },
            right: { style: "hair", color: { argb: "000000" } },
          },
          alignment: { horizontal: "right", vertical: "middle" },
        };

        if (colNumber === 2) {
          cell.style = {
            ...styleForTableBody,
            border: {
              ...styleForTableBody.border,
              left: { style: "thin", color: { argb: "000000" } },
            },
            alignment: { horizontal: "right", vertical: "middle" },
          };
        } else if (colNumber === 3) {
          cell.style = {
            ...styleForTableBody,
            border: {
              ...styleForTableBody.border,
              right: { style: "thin", color: { argb: "000000" } },
            },
            alignment: { horizontal: "right", vertical: "middle" },
          };
          cell.numFmt = "#,##0.00";
        }
      });

    for (let i = 0; i < 2 - allProjects.length; i++) {
      worksheet.addRow({});
    }
  });

  let bottomTablesFooter: any = {
    // col1: "Total1:",
    // col2: {
    //   formula: `SUM(C${rowNumberAfterHeader + 1}:C${
    //     rowNumberAfterHeader + allProjects.length
    //   })`,
    // },
    col4: "Total:",
    col5: {
      formula: `SUM(F${rowNumberAfterHeader + 1}:F${rowNumberAfterHeader + 2})`,
    },
  };

  if (chosenProjectId) {
    bottomTablesFooter = {
      col1: "Total: ",
      col3: {
        formula: `SUM(C${rowNumberAfterHeader + 1}:C${
          rowNumberAfterHeader + 2
        })`,
      },
    };
  }


  const totalStyle: any = {
    ...styleForTableBody,
    font: { size: 10, bold: true },
    border: {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "EFEFEF" },
    },
    alignment: { horizontal: "right", vertical: "middle" },
  }

  worksheet.getCell(`B${rowNumberAfterHeader + allProjects.length + 1}`).value = "Total:";
  worksheet.getCell(`B${rowNumberAfterHeader + allProjects.length + 1}`).style = totalStyle;
  worksheet.getCell(`C${rowNumberAfterHeader + allProjects.length + 1}`).style = totalStyle;
  worksheet.getCell(`C${rowNumberAfterHeader + allProjects.length + 1}`).numFmt = "#,##0.00";
  worksheet.getCell(`C${rowNumberAfterHeader + allProjects.length + 1}`).value = {formula: `SUM(C${rowNumberAfterHeader + 1}:C${rowNumberAfterHeader + allProjects.length})`};

  worksheet.addRow(bottomTablesFooter).eachCell((cell, colNumber) => {
    cell.style = {
      ...styleForTableBody,
      font: { size: 10, bold: true },
      border: {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "EFEFEF" },
      },
      alignment: { horizontal: "right", vertical: "middle" },
    };
    cell.numFmt = "#,##0.00";
  });

  const countOfHomeWork = +tasks
    .filter((item) => item.work_place.toLowerCase() === "home")
    .reduce((num, cur) => {
      return (num += +cur.hours);
    }, 0);
  const countOfFieldWork = +tasks
    .filter((item) => item.work_place.toLowerCase() === "field")
    .reduce((num, cur) => {
      return (num += +cur.hours);
    }, 0);

  const bdLeft: Partial<ExcelJS.Style> = {
    ...styleForTableBody,
    alignment: { vertical: "middle", horizontal: "right" },
    border: {
      ...styleForTableBody.border,
      left: { style: "thin", color: { argb: "000000" } },
    },
  };
  const bdRight: Partial<ExcelJS.Style> = {
    ...styleForTableBody,
    alignment: { vertical: "middle", horizontal: "right" },
    border: {
      ...styleForTableBody.border,
      right: { style: "thin", color: { argb: "000000" } },
    },
  };

  if (chosenProjectId) {
    worksheet.getCell(`B${rowNumberAfterHeader + 1}`).style = bdLeft;
    worksheet.getCell(`B${rowNumberAfterHeader + 2}`).style = bdLeft;
    worksheet.getCell(`C${rowNumberAfterHeader + 1}`).style = bdRight;
    worksheet.getCell(`C${rowNumberAfterHeader + 2}`).style = bdRight;

    worksheet.getCell(`B${rowNumberAfterHeader + 1}`).value = "Home";
    worksheet.getCell(`C${rowNumberAfterHeader + 1}`).numFmt = "#,##0.00";
    worksheet.getCell(`C${rowNumberAfterHeader + 1}`).value = countOfHomeWork / 8;
    worksheet.getCell(`B${rowNumberAfterHeader + 2}`).value = "Field";
    worksheet.getCell(`C${rowNumberAfterHeader + 2}`).numFmt = "#,##0.00";
    worksheet.getCell(`C${rowNumberAfterHeader + 2}`).value = countOfFieldWork / 8;

  } else {
    worksheet.getCell(`E${rowNumberAfterHeader + 1}`).style = bdLeft;
    worksheet.getCell(`E${rowNumberAfterHeader + 2}`).style = bdLeft;
    worksheet.getCell(`F${rowNumberAfterHeader + 1}`).style = bdRight;
    worksheet.getCell(`F${rowNumberAfterHeader + 2}`).style = bdRight;

    worksheet.getCell(`E${rowNumberAfterHeader + 1}`).value = "Home";
    worksheet.getCell(`F${rowNumberAfterHeader + 1}`).numFmt = "#,##0.00";
    worksheet.getCell(`F${rowNumberAfterHeader + 1}`).value = countOfHomeWork / 8;
    worksheet.getCell(`E${rowNumberAfterHeader + 2}`).value = "Field";
    worksheet.getCell(`F${rowNumberAfterHeader + 2}`).numFmt = "#,##0.00";
    worksheet.getCell(`F${rowNumberAfterHeader + 2}`).value = countOfFieldWork / 8;

  }

  if (!!approvalDate) {
    worksheet.addRow({});

    // worksheet
    //   .addRow({
    //     col1: "Approve",
    //   })
    //   .eachCell((cell, colNumber) => {
    //     cell.style = { font: { size: 18, bold: true } };
    //   });

    worksheet.addRow({});

    chosenTimesheet.approves.map((approval) => {
      return worksheet.addRow({
        col1: {
          richText: [
            { text: "Approved by: ", font: { bold: true, size: 12 } },
            {
              text: `${approval.user.first_name} ${approval.user.last_name}`,
              font: { size: 12, italic: true },
            },
          ],
        },
      });
    });

    worksheet.addRow({
      col1: {
        richText: [
          { text: "Date: ", font: { bold: true, size: 12 } },
          { text: String(approvalDate), font: { size: 12, italic: true } },
        ],
      },
    });
  }

  // if (chosenProjectId) {
  //     worksheet.addImage(logo, {
  //         // @ts-ignore
  //         tl: {col: 6.9, row: 0},
  //         // @ts-ignore
  //         br: {col: 7, row: 2.5}
  //     });
  // } else {
  //     worksheet.addImage(logo, {
  //         // @ts-ignore
  //         tl: {col: 7.9, row: 0},
  //         // @ts-ignore
  //         br: {col: 8, row: 2.5}
  //     });
  // }
};
