import {getBearer} from "../functions/getBearer";
import axios from "axios";
import {IAllUserProjects, ITimesheet} from "../models";
import {getApiLink} from "../functions/getApiLink";
import {GetAccessToken} from "./GetAccessToken";
import {setAllUserProjects} from "../storage/toolkit";

export async function SetAllUserProjects(dispatch: any, chosenTimesheet: ITimesheet) {
    const year = chosenTimesheet?.date ? "20" + chosenTimesheet?.date?.split('/')[2] : new Date().getFullYear()

    getBearer("get")
    await axios.get<IAllUserProjects>(getApiLink(`/api/user/projects_info/?year=${year}`)).then(({data}) => {
        dispatch(setAllUserProjects(data))
    }).catch(er => {
        er?.response?.status === 401 && GetAccessToken(dispatch, SetAllUserProjects)
    })
}