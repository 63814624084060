import React, { useEffect, useState } from "react";
import { useClickOutside } from "../../hooks/ClickOutside";
import { MonthNumber } from "../../constants/MonthNumber";
import { useDispatch, useSelector } from "react-redux";
import { ITimesheet, IUser } from "../../models";
import {
  setChosenTimesheet,
  setExpenses,
  setTasks,
  setTimesheet,
} from "../../storage/toolkit";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { SetTimesheet } from "../../api/SetTimesheet";
import { Years } from "../../constants/Years";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { getApiLink } from "../../functions/getApiLink";
import setCookie from "../../functions/setCookie";
import getCookies from "../../functions/getCookie";
import { SetSummaryEmployees } from "../../api/SetSummaryEmployees";
import { getBearer } from "../../functions/getBearer";

SwiperCore.use([Navigation]);

interface ITableSelectYearMonthProps {
  setMonth?: any;
  onSwitch?: any;
  setYear?: any;
  handleSetNewData?: any;
}

export const TableSelectYearMonth: React.FC<ITableSelectYearMonthProps> = ({
  setMonth,
  setYear,
  onSwitch,
  handleSetNewData,
}) => {
  const [isSelectActive, setIsSelectActive] = useState(false);

  const { rootEl } = useClickOutside(setIsSelectActive);
  const chosenTimesheet: ITimesheet = useSelector(
    (state: any) => state.toolkit.chosenTimesheet
  );
  const timesheet: ITimesheet[] = useSelector(
    (state: any) => state.toolkit.timesheet
  );
  const user: IUser = useSelector((state: any) => state.toolkit.user);
  const dispatch = useDispatch();

  const { timesheetId }: any = useParams();
  const [searchParams] = useSearchParams();

  const paramYear =
    searchParams.get("year") && Number(searchParams.get("year"));
  const paramMonth =
    searchParams.get("month") && Number(searchParams.get("month"));

  const dateNow = new Date();

  const [filedMonth, setFieldMonth] = useState(
    paramMonth ?? dateNow.getMonth() + 1
  );
  const [fieldYear, setFieldYear] = useState(
    paramYear ?? dateNow.getFullYear()
  );
  const [isHaveMonth, setIsHaveMonth] = useState<string[]>([]);

  const getTasksAndExpenses = (
    month: string | number,
    year: string | number,
    timesheetId?: string
  ) => {
    if (timesheetId || chosenTimesheet?.user?.id) {
      axios
        .get(
          getApiLink(
            `/api/timesheet/tasks/?month=${month}&year=${year}&user_id=${
              timesheetId ?? chosenTimesheet?.user?.id
            }`
          )
        )
        .then(({ data }) => {
          dispatch(setTasks(data));
        });
      axios
        .get(
          getApiLink(
            `/api/timesheet/expenses/?month=${month}&year=${year}&user_id=${
              timesheetId ?? chosenTimesheet?.user?.id
            }`
          )
        )
        .then(({ data }) => {
          dispatch(setExpenses(data));
        });

      return;
    }
  };

  const handleChooseMonth = (month: number) => {
    setFieldMonth(month);
    setCookie("timesheetMonth", month);

    if (onSwitch !== undefined) {
      onSwitch(month);
    }

    getTasksAndExpenses(month, fieldYear, timesheetId);

    if (setMonth !== undefined) {
      setMonth(month);
    }
  };

  const handleChangeYear = (year: number) => {
    setFieldYear(year);
    setCookie("timesheetYear", year);

    if (setYear !== undefined) {
      setYear(year);
    }

    getTasksAndExpenses(filedMonth, year, timesheetId);

    getBearer("get");
    axios
      .get(getApiLink(`/api/timesheet/my/?year=${year}`))
      .then(({ data }) => {
        dispatch(setTimesheet(data));
      });
  };

  useEffect(() => {
    if (timesheetId) {
      SetSummaryEmployees(
        dispatch,
        user,
        Number(getCookies("timesheetMonth")),
        Number(getCookies("timesheetYear"))
      );
      return;
    }

    const selectedTimesheet = timesheet.filter(
      (item) => Number(`${item.date[3]}${item.date[4]}`) === filedMonth
    )[0];
    dispatch(setChosenTimesheet(selectedTimesheet));
  }, [fieldYear, filedMonth]);

  useEffect(() => {
    if (timesheetId) return;

    SetTimesheet(dispatch, Number(fieldYear));
  }, [fieldYear]);

  useEffect(() => {
    if(!chosenTimesheet?.date) return;
    if (timesheetId) return;

    setFieldMonth(
      getCookies("timesheetMonth")
        ? Number(getCookies("timesheetMonth"))
        : +chosenTimesheet?.date?.split("/")[1]
    );
    setFieldYear(
      getCookies("timesheetYear")
        ? Number(getCookies("timesheetYear"))
        : Number("20" + chosenTimesheet?.date?.split("/")[2])
    );
  }, [chosenTimesheet])

  useEffect(() => {
    if (timesheetId) return;
  
    setIsHaveMonth([]);
    timesheet.map((item) => {
      setIsHaveMonth((prev) => [...prev, `${item.date[3]}${item.date[4]}`]);
    });

    dispatch(
      setChosenTimesheet(
        timesheet?.find((item) => item.date?.split("/")[1] == filedMonth) ??
          timesheet[0]
      )
    );
  }, [timesheet]);

  useEffect(() => {
    if (!timesheetId) return;

    setFieldMonth(
      paramMonth ??
        Number(getCookies("timesheetMonth")) ??
        new Date().getMonth() + 1
    );
    setFieldYear(
      paramYear ??
        Number(getCookies("timesheetYear")) ??
        new Date().getFullYear()
    );
    SetTimesheet(dispatch, Number(getCookies("timesheetYear")));
    SetSummaryEmployees(
      dispatch,
      user,
      Number(getCookies("timesheetMonth")),
      Number(getCookies("timesheetYear"))
    );
    getTasksAndExpenses(
      Number(getCookies("timesheetMonth")),
      Number(getCookies("timesheetYear")),
      timesheetId
    );
  }, [timesheetId]);

  return (
    <div
      ref={rootEl}
      className={
        isSelectActive
          ? "section-table__change-full-date drop-down is-active"
          : "section-table__change-full-date drop-down"
      }
    >
      <button
        className="section-table__change-full-date--target drop-down__target"
        type="button"
        onClick={(_) => setIsSelectActive((prev) => !prev)}
      >
        <span>
          {MonthNumber()[filedMonth].translate}, {fieldYear}
        </span>
        <svg
          width="10"
          height="7"
          viewBox="0 0 10 7"
          className="section-table__change-full-date--target-arrow drop-down__target--arrow"
        >
          <use xlinkHref="#drop-down-arrow"></use>
        </svg>
      </button>
      <div className="section-table__change-full-date--block drop-down__block">
        <div className="section-table__change-full-date--months">
          <ul>
            {Object.keys(MonthNumber()).map((item, index) => (
              <li
                key={item}
                className={
                  timesheetId ?? !isHaveMonth.some((m) => +m === +item)
                    ? "is-disabled"
                    : ""
                }
              >
                <label>
                  <input
                    type="radio"
                    name="month"
                    defaultValue={item}
                    onChange={(_) => handleChooseMonth(+item)}
                    checked={+item === filedMonth}
                  />
                  <span>{MonthNumber()[index + 1].translate}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="section-table__change-full-date--slider splide">
          <div className="splide__track">
            <Swiper
              slidesPerView={3}
              navigation={{
                nextEl: ".splide__arrow--next",
                prevEl: ".splide__arrow--prev",
              }}
              modules={[Navigation]}
              initialSlide={1}
            >
              {Years().map((year) => (
                <SwiperSlide key={year.year}>
                  <li className="splide__slide">
                    <label>
                      <input
                        disabled={timesheetId ?? year.isDisabled}
                        onChange={(_) => handleChangeYear(+year.year)}
                        defaultValue={+year.year}
                        checked={fieldYear === +year.year}
                        type="radio"
                        name="year"
                      />
                      <span>{year.year}</span>
                    </label>
                  </li>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev" type="button">
              <svg width="7" height="10" viewBox="0 0 7 10">
                <use xlinkHref="#arrow-prev"></use>
              </svg>
            </button>
            <button className="splide__arrow splide__arrow--next" type="button">
              <svg width="7" height="10" viewBox="0 0 7 10">
                <use xlinkHref="#arrow-next"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
